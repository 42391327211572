import React, { useEffect, useState } from 'react';
import './App.css';
import NumberContainer from './components/NumberContainer';
import useAudio from './hooks/useAudio';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { IEventInfo, IPrizesData, IResponeGetLotoInfo, ITicketsData } from './types/models/Master';
import OTPInput from 'react-otp-input';
import AppLoader from './components/AppLoader';

const DEFAULT_MAX_COUNT = 10;
const API_URL = process.env.REACT_APP_API_URL;

function App() {
  const [_, toggle] = useAudio('/static/sound/sound-lucky-draw.wav', true);
  const [____, toggleSuccess] = useAudio('/static/sound/success.wav');
  const [isDelete, setIsDelete] = useState({
    open: false,
    id: ''
  });
  const [isVerify, setIsVerify] = useState(true);
  const [isStart, setIsStart] = useState(false);
  const [isStop, setIsStop] = useState(false);
  const [isCallStart, setIsCallStart] = useState(false);
  const [numCount, setNumCount] = useState(0);
  const [ticketActive, setTicketActive] = useState(''.split(''))
  const [infoActive, setInfoActive] = useState({ phone: '', name: '' });
  const [searchParams, __] = useSearchParams();
  const [tickets, setTickets] = useState<ITicketsData[]>([]);
  const [step, setStep] = useState(1);
  const [prizes, setPrizes] = useState<IPrizesData[]>([]);
  const [infoGame, setInfoGame] = useState<IEventInfo>();
  const [otp, setOtp] = useState('');
  const [otpActive, setOtpActive] = useState('');
  const [errMessVerifyOTP, setErrMessVerifyOTP] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false);
  const [isDontStart, setIsDontStart] = useState({
    open: false,
    message: ''
  });
  let gRef = searchParams.get('gRef');
  let token = searchParams.get('tk');

  const handleChangeOTP = (val: string) => {
    setOtp(val);
    if (errMessVerifyOTP) setErrMessVerifyOTP(undefined);
  }

  const onCancelResult = () => {
    setIsLoading(true);
    if (otp === otpActive) {
      if (tickets.length !== 0 && tickets?.filter((item) => item.status === 'CheckedIn')?.length < prizes.length) {
        setIsLoading(false);
        return setIsDontStart({
          open: true,
          message: "Số lượng KH check-in chưa đủ, không thể bắt đầu thực hiện quay số"
        });
      }
      let tempFind = tickets?.find((item) => item.raffleTicketReferenceCode.includes(ticketActive.join('')));
      let prizesTemp = prizes;
      let ticketsTemp = tickets;
      if (ticketsTemp?.length) {
        let ind = tickets.findIndex((item) => item.raffleTicketReferenceCode.includes(ticketActive.join('')));
        if (ind > -1) {
          ticketsTemp = ticketsTemp.filter((item, index) => ind !== index);
        }
      }

      axios.post(`${API_URL}/api/v1/game_managements/game/cancelResult`, {
        ticketCode: isDelete.id,
        canceledPrize: prizes[prizes.length - 1]?.prizeName,
        customerReferenceCode: tempFind?.customerReferenceCode,
        remainingPrizes: prizes.map((item) => {
          return {
            priority: item.index,
            prizeName: item.prizeName,
          }
        })
      }, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then((res) => {
        if (res.data.succeeded) {
          setIsLoading(false);
          setIsDelete({ open: false, id: '' });
          setStep(1);

          setIsStop(false);
          setIsStart(false);
          setTicketActive([]);
          setInfoActive({
            name: '',
            phone: '',
          })
          setPrizes(prizesTemp);
          setTickets(ticketsTemp);
        } else {
          setIsLoading(false);
          setIsDontStart({
            open: true,
            message: res?.data?.message
          });
        }
      }).catch((err) => {
        setIsLoading(false);
        setIsDontStart({
          open: true,
          message: "Đã có lỗi xảy ra, vui lòng thử lại!"
        });
      })
    } else {
      setErrMessVerifyOTP('Sai OTP, vui lòng thử lại!');
      setIsLoading(false);
    }
  }
  const handleStart = () => {
    if (tickets.length !== 0 && tickets?.filter((item) => item.status === 'CheckedIn')?.length < prizes.length) {
      return setIsDontStart({
        open: true,
        message: "Số lượng KH check-in chưa đủ, không thể bắt đầu thực hiện quay số"
      });
    }
    if (isVerify) return;
    if (!gRef) return;
    if (!isCallStart) {
      setIsLoading(true);
      axios.post(`${API_URL}/api/v1/game_managements/start/${gRef}`, undefined, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then((res) => {
        if (res.data.succeeded) {
          (toggle as any)();
          const random = Math.floor(Math.random() * tickets.length);
          const strTicket = tickets[random]?.raffleTicketReferenceCode.split('Ticket-').join('');
          setTicketActive(strTicket.split(''));
          setInfoActive({
            name: tickets[random]?.customerName || '',
            phone: tickets[random]?.customerPhoneNumber || '',
          })
          setIsLoading(false);
          setIsStop(false);
          setIsStart(true);
          setIsCallStart(true);
          setNumCount(DEFAULT_MAX_COUNT);
        } else {
          setIsLoading(false);
        }
      }).catch((err) => {
        setIsLoading(false);
      })
    } else {
      (toggle as any)();
      const random = Math.floor(Math.random() * tickets.length);
      const strTicket = tickets[random]?.raffleTicketReferenceCode.split('Ticket-').join('');
      setTicketActive(strTicket.split(''));
      setInfoActive({
        name: tickets[random]?.customerName || '',
        phone: tickets[random]?.customerPhoneNumber || '',
      })
      setIsLoading(false);
      setIsStop(false);
      setIsStart(true);
      setIsCallStart(true);
      setNumCount(DEFAULT_MAX_COUNT);
    }
  }
  const handleStop = () => {
    (toggle as any)();
    (toggleSuccess as any)();
    setIsStop(true);
    setIsStart(false);
    setStep(1);
  }
  const handleContinue = () => {
    let tempFind = tickets?.find((item) => item.raffleTicketReferenceCode.includes(ticketActive.join('')));
    let prizesTemp = prizes;
    let ticketsTemp = tickets;
    if (ticketsTemp.length) {
      let ind = tickets.findIndex((item) => item.raffleTicketReferenceCode.includes(ticketActive.join('')));
      if (ind > -1) {
        ticketsTemp = ticketsTemp.filter((item, index) => ind !== index);
      }
    }
    if (prizesTemp.length) {
      prizesTemp = prizesTemp.filter((item, index) => prizesTemp.length - 1 !== index);
    }

    if (step > 1) {
      setStep(1);
      setIsStop(false);
      setIsStart(false);
      setTicketActive([]);
      setInfoActive({
        name: '',
        phone: '',
      })
      setPrizes(prizesTemp);
      setTickets(ticketsTemp);
      return;
    }
    if (tickets.length === 0 && tickets?.filter((item) => item.status === 'CheckedIn')?.length <= prizes.length) {
      return setIsDontStart({
        open: true,
        message: "Số lượng KH check-in chưa đủ, không hủy giải người này vì sẽ không đủ số lượng KH để quay số"
      });
    }

    if (tempFind) {
      setIsLoading(true);
      axios.put(`${API_URL}/api/v1/game_managements/loto/update/prizeWinner`, {
        gameReferenceCode: gRef,
        ticketReferenceCode: tempFind.raffleTicketReferenceCode,
        customerReferenceCode: tempFind.customerReferenceCode,
        giftReferenceCode: prizes[prizes.length - 1].giftReferenceCode,
        prizeName: prizes[prizes.length - 1]?.prizeName
      }, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then((res) => {
          if (res?.data?.succeeded) {
            setStep(step + 1);
            // setIsStop(false);
            // setIsStart(false);
            // setTicketActive([]);
            // setInfoActive({
            //   name: '',
            //   phone: '',
            // })
            // setPrizes(prizesTemp);
            // setTickets(ticketsTemp);
          } else {
            alert(res?.data?.message || 'Đã có lỗi xảy ra, vui lòng thử lại!')
          }
        })
        .catch((err) => {
          alert('Đã có lỗi xảy ra, vui lòng thử lại!')
        })
        .finally(() => setIsLoading(false))
    }
  }
  // const handleCancel = () => {
  //   // (toggleSuccess as any)();
  //   setIsStop(false);
  //   setIsStart(false);
  //   setTicketActive([]);
  // }

  const returnHideNumber = (phoneNumber: string) => {
    if (phoneNumber?.length === 10) {
      const phoneNumArray = phoneNumber.split("");
      phoneNumArray[4] = "*"
      phoneNumArray[5] = "*"
      phoneNumArray[6] = "*"
      const updatedPhoneNumber = phoneNumArray.join("");
      return updatedPhoneNumber;
    }
    return phoneNumber;
  }

  const handleFirstGame = () => {
    setIsLoading(true);
    axios.get(`${API_URL}/api/v1/game_managements/loto/ticket/${gRef}?pinCode=${otp}`, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then((res) => {
        const dataRes: IResponeGetLotoInfo = res.data;
        if (dataRes?.succeeded) {
          setTickets(dataRes?.data?.tickets ?? []);
          setPrizes(dataRes?.data?.prizes ?? []);
          setInfoGame({
            eventAddress: dataRes?.data?.eventAddress,
            eventDate: dataRes?.data?.eventDate,
            gameName: dataRes?.data?.gameName,
          })
          setOtpActive(otp);
          setOtp('');
          setIsLoading(false);
          setIsVerify(false);
        } else {
          setErrMessVerifyOTP(dataRes?.message || 'Đã có lỗi xảy ra, vui lòng thử lại!');
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setErrMessVerifyOTP('Đã có lỗi xảy ra, vui lòng thử lại!');
        setIsLoading(false);
      })
  }

  useEffect(() => {
    if (numCount === 0 && isStart) {
      return handleStop();
    }
    if (numCount > 0) {
      setTimeout(() => {
        setNumCount(numCount - 1);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numCount])
  return (
    <div className='app-container'>
      <div className='box-bottom'>
        <div className='header-h' />
        <div className='logo-vita' />
        <div className='header-top'>
          <img src='/static/logo.png' alt='logo' />
          <div className='desc-right'>
            <p className='store-name'>{infoGame?.gameName}</p>
            <p className='store-date'>{infoGame?.eventDate}</p>
            <p className='store-address'>{infoGame?.eventAddress}</p>
          </div>
        </div>


        <div className='box-container'>
          <div className='gift-container'>
            <div className='gift-left-item'>
              <p className='name'>{prizes[prizes.length - 1]?.prizeName}</p>
              {prizes.length - 1 <= 0 ? null : (
                <p className='remaining-prizes'>Còn {prizes.length - 1} giải</p>
              )}
              <p className='price'>{prizes[prizes.length - 1]?.giftName}</p>
            </div>
            {prizes?.length ? (
              <div className='gift-right-item'>
                <img src={prizes[prizes.length - 1]?.giftImg} alt='gift' />
              </div>
            ) : null}
          </div>


          <div className='box-wheel'>
            <div className='box-header'>
              NGƯỜI MAY MẮN TRÚNG GIẢI
            </div>
            <div className='box-wheel-item'>

              <div className='lucky-drawer-container' style={{ flexDirection: "column" }}>
                <div style={{ display: "flex", position: "relative" }}>
                  {ticketActive?.length ? ticketActive.map((item, index) => (
                    <NumberContainer key={index} value={item} isStart={isStart} isStop={isStop} height={80} ind={index} />
                  )) : <div style={{ height: 116 }} />}
                  {isStop && step === 1 && (
                    <div onClick={() => setIsDelete({ open: true, id: ticketActive.join('') })} style={{ position: "absolute", right: -102, top: 16, cursor: "pointer" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <path d="M24 3C12.4031 3 3 12.4031 3 24C3 35.5969 12.4031 45 24 45C35.5969 45 45 35.5969 45 24C45 12.4031 35.5969 3 24 3ZM31.7531 31.9781L28.6594 31.9641L24 26.4094L19.3453 31.9594L16.2469 31.9734C16.0406 31.9734 15.8719 31.8094 15.8719 31.5984C15.8719 31.5094 15.9047 31.425 15.9609 31.3547L22.0594 24.0891L15.9609 16.8281C15.9043 16.7594 15.8729 16.6734 15.8719 16.5844C15.8719 16.3781 16.0406 16.2094 16.2469 16.2094L19.3453 16.2234L24 21.7781L28.6547 16.2281L31.7484 16.2141C31.9547 16.2141 32.1234 16.3781 32.1234 16.5891C32.1234 16.6781 32.0906 16.7625 32.0344 16.8328L25.9453 24.0938L32.0391 31.3594C32.0953 31.4297 32.1281 31.5141 32.1281 31.6031C32.1281 31.8094 31.9594 31.9781 31.7531 31.9781Z" fill="white" />
                      </svg>
                    </div>
                  )}
                </div>
                {isStop ? (
                  <p className='name-phone' style={{ fontWeight: 500, color: 'white' }}>
                    {returnHideNumber(infoActive?.phone)} - {infoActive?.name}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          {!isStart && !isStop && (
            <button className='lucky-btn-container' onClick={handleStart}>
              <div style={{ position: "relative", height: 67 }}>
                <img src="/static/start-ico.png" alt="start" style={{ position: "absolute", left: -52, top: -12 }} />
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="67" viewBox="0 0 13 67" fill="none">
                  <rect x="0.5" width="12" height="67" rx="4" fill="#82633D" />
                  <rect x="0.5" width="12" height="67" rx="4" fill="#82633D" />
                  <rect x="7.5" width="5" height="67" rx="2.5" fill="url(#paint0_linear_5990_56)" />
                  <defs>
                    <linearGradient id="paint0_linear_5990_56" x1="17.0959" y1="33.5" x2="7.5" y2="33.5" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#1C150D" />
                      <stop offset="1" stopColor="#82633D" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <p>Bắt đầu</p>
            </button>
          )}
          {/* {isStart && <button className='lucky-btn-container' onClick={handleStop}>Dừng</button>} */}
          {isStop && (
            <div style={{ display: 'flex', gap: 12 }}>
              <button className='lucky-btn-container' onClick={handleContinue}>{
                prizes.length === 0 ? 'Xác nhận giải' : step === 1 ? 'Xác nhận' : 'Tiếp tục'
              }</button>
            </div>
          )}
        </div>

        {isVerify && (
          <div className='modal-input'>
            <div className='input-container'>
              <p className='title'>Nhập mã pin của Game</p>

              <OTPInput
                value={otp}
                shouldAutoFocus
                onChange={handleChangeOTP}
                numInputs={6}
                inputType='number'
                inputStyle={{ width: 40, height: 40, background: '#F1F5F9', fontSize: 14, border: '1px solid #94A3B8', borderRadius: 12 }}
                containerStyle={{ justifyContent: 'space-between', marginTop: 24, marginBottom: 8 }}
                renderSeparator={<span></span>}
                renderInput={(props) => <input {...props} />}
              />

              {errMessVerifyOTP && <p style={{ background: '#EF44441A', color: '#F75555', fontSize: 13, fontWeight: 500, padding: '4px 12px', borderRadius: 6, marginTop: 12, textAlign: 'left' }}>{errMessVerifyOTP}</p>}

              <button
                onClick={handleFirstGame}
                className='btn-accept'
                style={otp.length < 6 ? { backgroundColor: '#F1F5F9', color: '#94A3B8' } : {}}
                disabled={otp?.length < 6}
              >
                Xác nhận
              </button>
            </div>
          </div>
        )}
        {isDelete.open && (
          <div className='modal-dont-start'>
            <div className='dont-start-container' style={{ textAlign: "left" }}>
              <p className='title'>Xác nhận hủy giải</p>
              <p className='desc'>
                Giải thưởng sẽ được giữ lại, mã Code <span style={{ fontWeight: "bold" }}>{isDelete.id}</span> sẽ không được tham gia vào vòng quay nữa
              </p>

              <OTPInput
                value={otp}
                shouldAutoFocus
                onChange={handleChangeOTP}
                numInputs={6}
                inputType='number'
                inputStyle={{ width: 40, height: 40, background: '#F1F5F9', fontSize: 14, border: '1px solid #94A3B8', borderRadius: 12 }}
                containerStyle={{ justifyContent: 'space-between', marginTop: 24, marginBottom: 8 }}
                renderSeparator={<span></span>}
                renderInput={(props) => <input {...props} />}
              />
              {errMessVerifyOTP && <p style={{ background: '#EF44441A', color: '#F75555', fontSize: 13, fontWeight: 500, padding: '4px 12px', borderRadius: 6, marginTop: 12, textAlign: 'left' }}>{errMessVerifyOTP}</p>}


              <div className='action-list'>
                <button
                  onClick={() => setIsDelete({ open: false, id: '' })}
                  className='btn-accept'
                  style={{ background: "#FCFCFD", color: "#000000D9", border: "1px solid #F2F4F7", padding: "12px", borderRadius: 12 }}
                >
                  Không hủy
                </button>
                <button
                  onClick={onCancelResult}
                  className='btn-accept'
                  style={otp?.length === 6 ? { background: "#FCFCFD", color: "#F5222D", border: "1px solid #F5222D", padding: "12px", borderRadius: 12 } : {
                    background: "#FCFCFD", color: "#00000040", border: "1px solid #F2F4F7", padding: "12px", borderRadius: 12
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <g clipPath="url(#clip0_5854_968)">
                      <path d="M4.625 1.87366H4.5C4.56875 1.87366 4.625 1.81741 4.625 1.74866V1.87366H9.375V1.74866C9.375 1.81741 9.43125 1.87366 9.5 1.87366H9.375V2.99866H10.5V1.74866C10.5 1.19709 10.0516 0.748657 9.5 0.748657H4.5C3.94844 0.748657 3.5 1.19709 3.5 1.74866V2.99866H4.625V1.87366ZM12.5 2.99866H1.5C1.22344 2.99866 1 3.2221 1 3.49866V3.99866C1 4.06741 1.05625 4.12366 1.125 4.12366H2.06875L2.45469 12.2955C2.47969 12.8283 2.92031 13.2487 3.45313 13.2487H10.5469C11.0813 13.2487 11.5203 12.8299 11.5453 12.2955L11.9313 4.12366H12.875C12.9438 4.12366 13 4.06741 13 3.99866V3.49866C13 3.2221 12.7766 2.99866 12.5 2.99866ZM10.4266 12.1237H3.57344L3.19531 4.12366H10.8047L10.4266 12.1237Z" fill={otp?.length === 6 ? "#F5222D" : "#00000040"} />
                    </g>
                    <defs>
                      <clipPath id="clip0_5854_968">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span style={{ paddingLeft: 4 }}>Hủy giải</span>
                </button>
              </div>
            </div>
          </div>
        )}
        {isDontStart.open && (
          <div className='modal-dont-start'>
            <div className='dont-start-container'>
              <p className='title'>{isDontStart.message}</p>

              <div>
                <button
                  onClick={() => setIsDontStart({
                    open: false,
                    message: ""
                  })}
                  className='btn-accept'
                >
                  Đã hiểu
                </button>
              </div>
            </div>
          </div>
        )}
        {isCallStart && prizes.length === 0 && (
          <div className='modal-dont-start'>
            <div className='dont-start-container'>
              <p className='title-end-game'>Trò chơi đã kết thúc</p>

              <p className='desc-end-game'>Các phần quà giải khuyến khích sẽ giành cho những khách hàng còn lại.</p>

            </div>
          </div>
        )}


        <img src='/static/gift-left.png' alt='gift-left' className='gift-left-container' />
        <img src='/static/gift-right.png' alt='gift-right' className='gift-right-container' />
      </div>
      {isLoading && <AppLoader />}
    </div>
  );
}

export default App;
