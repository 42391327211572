import React from 'react'

interface INumberItem {
  value: number | string;
  count: number;
  height: number;
}
const NumberItem: React.FC<INumberItem> = ({
  count, value, height
}) => {
  return (
    <p
      className='number-item'
      style={{
        height: height,
        transform: `translateY(${count}px)`
      }}>
      {value}
    </p>
  )
}

export default React.memo(NumberItem)