import React, { useEffect } from 'react'
import NumberItem from './NumberItem'

const arrNum = [
  'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h',
  'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p',
  'q', 'r', 's', 't', 'u', 'v', 'w', 'x',
  'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F',
  'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N',
  'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V',
  'W', 'X', 'Y', 'Z', '0', '1', '2', '3',
  '4', '5', '6', '7', '8', '9',
];
const arrNumPhone = [
  '0', '1', '2', '3',
  '4', '5', '6', '7', '8', '9',
];

interface INumberContainer {
  isStart: boolean;
  isStop: boolean;
  height: number;
  value: number | string;
  ind: number;
}

const NumberContainer: React.FC<INumberContainer> = ({
  isStart,
  isStop,
  height,
  value,
  ind
}) => {
  const [count, setCount] = React.useState(0);
  useEffect(() => {
    const timeCall = () => {
      setTimeout(() => {
        if (isStart) {
          if (count >= (-height * 9) && count <= (-height * 8)) {
            setCount(0);
          } else {
            setCount(count - (height / 10));
          }
        } else {
          if (isStop) {
            // setCount(-ind * height);
            setCount(-arrNum.findIndex((item) => item === value.toString()) * height)
          }
        }
      }, 1)
    }
    timeCall();
  }, [count, isStart, isStop, height, value])
  return (
    <div style={{ fontSize: 44, height: height, overflow: 'hidden', filter: isStop || !isStart ? undefined : 'blur(1px)' }}>
      {isStop ? (
        arrNum.map((item) => (
          <NumberItem key={item} value={item} count={count} height={height} />
        ))
      ) : (
        arrNumPhone.map((item) => (
          <NumberItem key={item} value={item} count={count} height={height} />
        ))
      )}
    </div>
  )
}

export default NumberContainer