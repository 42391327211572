import { useEffect, useState } from 'react';

const useAudio = (url: string, loop?: boolean) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => {
    audio.loop = loop ? loop : false;
    setPlaying(!playing)
  };

  useEffect(() => {
    playing ? audio?.play() : audio?.pause();
  },
    [playing]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

export default useAudio;